import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../../modules/Grid";
import { Form, Input, InputPhone } from "../../../utils/Form";
import Loading from "../../../utils/Loading";
import alertMessageActions from "../../../actions/alertMessage";
import userActions from "../../../actions/user";
import axios from "axios";
import env from "../../../env";
import cookie from "../../../utils/Cookie";

class Formulario extends Component {
  componentDidMount() {
    let location = window.location.href;

    this.props.dispatch(
      alertMessageActions.setAlert(
        "blue",
        "Dúvidas sobre sua conta? Entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).",
        false
      )
    );

    if (
      location.indexOf("validacao=true") > -1 &&
      this.props.usuario.email_validado === "N"
    ) {
      this.sendEmailValidateLink();
    }
  }
  sendUpdate() {
    let form = document.getElementById("form-meus-dados");
    let salvarDados;
    let obj = {};
    obj["genericCategory"] = "engajamento";
    obj["genericAction"] = "meu_estadao";
    if (form) {
      let bodyFormData = new FormData(form);
      Loading.add();
      Form.send("/user", bodyFormData)
        .then((response) => {
          Loading.remove();
          window.scrollTo(0, 0);
          let { data, status } = response;
          if (status === 200) {
            cookie.set("OESP_TALK", "", -1);
            this.props.dispatch(
              alertMessageActions.setAlert("green", "Salvo com sucesso!")
            );
            this.props.dispatch(userActions.getUser(true));
            obj["genericLabel"] = "salvar_dados_pessoais_sucesso";
            salvarDados = new CustomEvent("alertPush", { detail: obj });
            window.dispatchEvent(salvarDados);
          } else if (data.error) {
            let message = data.error.message || "Ops algo deu errado!";
            Form.showErrors(message);
            obj["genericLabel"] = "salvar_dados_pessoais_recusado";
            salvarDados = new CustomEvent("alertPush", { detail: obj });
            window.dispatchEvent(salvarDados);
          }
        })
        .catch((e) => {
          Loading.remove();
        });
    }
  }

  redirectTo(page) {
    this.props.dispatch({ type: "REDIRECT", redirect: "/" + page });
  }

  sendEmailValidateLink() {
    Loading.add();
    if (this.props.usuario.email) {
      let emailValidationForm = new FormData();

      emailValidationForm.set(
        "url_callback",
        window.origin + "/validar-email/"
      );

      axios
        .post(env.API_URL + "/utils/validate-email", emailValidationForm, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            this.props.dispatch(
              alertMessageActions.setAlert(
                "green",
                "Sucesso! Um e-mail com um link de validação foi enviado.",
                false
              )
            );
          } else {
            this.props.dispatch(
              alertMessageActions.setAlert(
                "red",
                "Ops, algo de inesperado ocorreu, entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).",
                false
              )
            );
          }
          Loading.remove();
        })
        .catch((error) => {
          this.props.dispatch(
            alertMessageActions.setAlert(
              "red",
              "Ops, algo de inesperado ocorreu, entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).",
              false
            )
          );
          Loading.remove();
        });
    }
  }

  render() {
    let usuario = this.props.usuario;
    let load = !usuario.id;
    usuario.nome_completo = usuario.nome + " " + usuario.sobrenome;

    return (
      <div>
        <div className="title-mobile">Informações pessoais</div>
        <div className="block-single">
          <form
            id="form-meus-dados"
            action=""
            method="POST"
            className="content-form"
          >
            {usuario.telefone && (
              <input name="has_tel" type="hidden" value="1" />
            )}

            {usuario.celular && (
              <input name="has_cel" type="hidden" value="1" />
            )}

            <div className="row">
              <Grid cols="12">
                <Input
                  label="Nome Completo"
                  name="nome_completo"
                  value={usuario.nome_completo}
                  load={load}
                />
              </Grid>
            </div>
            <div className="row">
              <Grid cols="12">
                <Input
                  readonly={true}
                  type="email"
                  label="E-mail"
                  value={usuario.email}
                  load={load}
                />
              </Grid>
            </div>
            <div className="row">
              <Grid cols="12 12 6 6 6">
                <InputPhone
                  name="telefone"
                  label="Telefone"
                  value={usuario.ddd_tel + usuario.telefone}
                  load={load}
                />
              </Grid>
              <Grid cols="12 12 6 6 6">
                <InputPhone
                  name="celular"
                  label="Celular"
                  value={usuario.ddd_cel + usuario.celular}
                  load={load}
                />
              </Grid>
            </div>
          </form>
        </div>

        <button
          className="btn blue salvar-dados"
          data-dtkey="salvar_dados"
          onClick={() => {
            this.sendUpdate();
          }}
        >
          <i className="icon-floppy" /> Salvar dados
        </button>

        <button
          className="btn blue redefinir-senha"
          data-dtkey="redefinir_senha"
          onClick={() => {
            this.redirectTo("alterar-senha");
          }}
        >
          <i className="icon-arrows-cw" /> Alterar senha
        </button>

        <a href="alterar-email">
          <button
            className="btn blue redefinir-senha"
            data-dtkey="redefinir_email"
          >
            <i className="icon-arrows-cw" /> Alterar e-mail
          </button>
        </a>

        {usuario.email_validado !== "S" && (
          <button
            className="btn red salvar-dados valid-email"
            type="button"
            data-dtkey="validar_email"
            onClick={() => {
              this.sendEmailValidateLink();
            }}
          >
            <i className="icon-mail" /> Validar e-mail
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.user,
  };
};

export default connect(mapStateToProps)(Formulario);
