import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "../../modules/Grid";
import {
  Form,
  Input,
  InputPhone,
  Select,
  InputCustomDate,
} from "../../../utils/Form";
import Loading from "../../../utils/Loading";
import alertMessageActions from "../../../actions/alertMessage";
import userActions from "../../../actions/user";
import subscriptionActions from "../../../actions/subscription";
import env from "../../../env";
import axios from "axios";
import cookie from "../../../utils/Cookie";
import Universidades from "../../../utils/Universidades";

class FormularioAssinante extends Component {
  state = {
    uf: null,
    states: [],
    state_load: false,
    faculdades: {},
    instituicao_ensino: null,
    cursos: {},
    curso: null,
    gamer: false,
    apelido: "",
    dt_inicio: "",
    dt_termino: "",
    dt_nascimento: "",
    id_estudante: "",
  };

  componentWillReceiveProps(nextProps, nextContext) {}
  componentWillMount() {}

  componentDidMount() {
    this.setState({
      faculdades: Universidades.getFaculdades(),
      cursos: Universidades.getCursos(),
    });
    let location = window.location.href;
    this.props.dispatch(
      alertMessageActions.setAlert(
        "blue",
        "Dúvidas sobre sua conta? Entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).",
        false
      )
    );

    if (
      location.indexOf("validacao=true") > -1 &&
      this.props.usuario.email_validado === "N"
    ) {
      this.sendEmailValidateLink();
    }
    this._updateStates();
    this.componentDidUpdate();
  }

  componentWillUnmount() {
    this.props.dispatch(alertMessageActions.removeAlert());
  }

  componentDidUpdate() {
    if (!this.props.assinatura && this.props.assinatura_selecionada) {
      this.props.dispatch(
        subscriptionActions.getDados(this.props.assinatura_selecionada)
      );
    }
  }

  _updateStates() {
    return new Promise((resolve, reject) => {
      if (!this.state.state_load) {
        this.setState({
          state_load: true,
        });
        axios
          .get(env.API_URL + "/utils/address/states", { withCredentials: true })
          .then((response) => {
            let { data } = response,
              states = {};
            for (let i in data) {
              if (data.hasOwnProperty(i)) states[data[i].uf] = data[i].uf;
            }

            this.setState({
              states: states,
              state_load: false,
            });

            resolve(states);
          })
          .catch((response) => {
            this.setState({
              state_load: false,
            });
            reject(response);
          });
      } else {
        resolve(this.state.states);
      }
    });
  }

  sendEmailValidateLink() {
    Loading.add();
    if (this.props.usuario.email) {
      let emailValidationForm = new FormData();

      emailValidationForm.set(
        "url_callback",
        window.origin + "/validar-email/"
      );

      axios
        .post(env.API_URL + "/utils/validate-email", emailValidationForm, {
          withCredentials: true,
        })
        .then((response) => {
          if (response.status === 200) {
            this.props.dispatch(
              alertMessageActions.setAlert(
                "green",
                "Sucesso! Um e-mail com um link de validação foi enviado.",
                false
              )
            );
          } else {
            this.props.dispatch(
              alertMessageActions.setAlert(
                "red",
                "Ops, algo de inesperado ocorreu, entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).",
                false
              )
            );
          }
          Loading.remove();
        })
        .catch((error) => {
          this.props.dispatch(
            alertMessageActions.setAlert(
              "red",
              "Ops, algo de inesperado ocorreu, entre em contato com nosso SAC: 4003-5323(Capitais) ou 0800-014-7720 (Demais Localidades).",
              false
            )
          );
          Loading.remove();
        });
    }
  }

  validateDOB(dateString) {
    let regex = /(((0|1)[0-9]|2[0-9]|3[0-1])\/(0[1-9]|1[0-2])\/((19|20)\d\d))$/;

    if (dateString.length < 10) {
      Form.showErrors({
        dt_nascimento:
          "Data de nascimento inválida, formato aceito é dia, mês e ano com 4 dígitos.",
      });
      this.props.dispatch(
        alertMessageActions.setAlert(
          "red",
          "Data de nascimento inválida, formato aceito é dia, mês e ano com 4 dígitos.",
          false
        )
      );
      return false;
    }
    if (regex.test(dateString)) {
      let parts = dateString.split("/");
      let dtDOB = new Date(parts[1] + "/" + parts[0] + "/" + parts[2]);
      let dtCurrent = new Date();
      if (dtCurrent.getFullYear() - dtDOB.getFullYear() < 18) {
        this.props.dispatch(
          alertMessageActions.setAlert(
            "red",
            "A data de nascimento preenchida é inválida. É necessário ter acima de 18 anos para se cadastrar.",
            false
          )
        );
        Form.showErrors({
          dt_nascimento: "É necessário ter acima de 18 anos para se cadastrar.",
        });
        return false;
      }

      if (dtCurrent.getFullYear() - dtDOB.getFullYear() === 18) {
        if (dtCurrent.getMonth() < dtDOB.getMonth()) {
          return false;
        }
        if (dtCurrent.getMonth() === dtDOB.getMonth()) {
          //CD: 11/06/2018 and DB: 15/06/2000. Will turned 18 on 15/06/2018.
          if (dtCurrent.getDate() < dtDOB.getDate()) {
            return false;
          }
        }
      }
      return true;
    }
  }

  validaDataCurso() {
    try {
      let data_inicio = document.querySelector('[name="dt_inicio"]');
      let data_termino = document.querySelector('[name="dt_termino"]');

      if (data_inicio.value.length <= 0 && this.state.dt_inicio.length > 0) {
        data_inicio.value = this.state.dt_inicio;
      }

      if (data_termino.value.length <= 0 && this.state.dt_termino.length > 0) {
        data_termino.value = this.state.dt_termino;
      }

      let r_inicio = parseInt(
        data_inicio.value.split("/")[1] + "" + data_inicio.value.split("/")[0]
      );
      let r_termino = parseInt(
        data_termino.value.split("/")[1] + "" + data_termino.value.split("/")[0]
      );
      let hasError = false;
      if (r_inicio > r_termino) {
        data_inicio
          .closest(".input-control")
          .setAttribute("data-error", "Datas inválidas.");
        data_termino
          .closest(".input-control")
          .setAttribute("data-error", "Datas inválidas.");
        this.props.dispatch(
          alertMessageActions.setAlert(
            "red",
            "A data de término do curso não pode ser menor que a data início.",
            false
          )
        );
        hasError = true;
      }
      let errorFormat =
        "Formato de data é inválida, formato aceito é mês e ano com 4 dígitos.";
      if (data_inicio.value.length < 7) {
        Form.showErrors({
          dt_inicio: "Data inválida, formato aceito é mês e ano com 4 dígitos.",
        });
        this.props.dispatch(
          alertMessageActions.setAlert("red", errorFormat, false)
        );
        hasError = true;
      }
      if (data_termino.value.length < 7) {
        Form.showErrors({
          dt_termino:
            "Data inválida, formato aceito é mês e ano com 4 dígitos.",
        });
        this.props.dispatch(
          alertMessageActions.setAlert("red", errorFormat, false)
        );
        hasError = true;
      }
      if (
        parseInt(data_termino.value.split("/")[1]) < new Date().getFullYear() &&
        parseInt(this.state.dt_termino.split("/")[1]) < new Date().getFullYear()
      ) {
        Form.showErrors({
          dt_termino: "O ano término não pode ser inferior ao ano atual.",
        });
        this.props.dispatch(
          alertMessageActions.setAlert(
            "red",
            "O ano término não pode ser inferior ao ano atual.",
            false
          )
        );
        hasError = true;
      }
      if (hasError) {
        return false;
      }
      data_inicio.closest(".input-control").removeAttribute("data-error");
      data_termino.closest(".input-control").removeAttribute("data-error");
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  }

  validaRequireds() {
    return new Promise((resolve, reject) => {
      let fieldsJson = {
        apelido: {
          message: "O campo apelido é obrigatório.",
          valid: document.querySelector('[name="apelido"]').value.length > 0,
        },
        dt_nascimento: {
          message: "O campo data de nascimento é obrigatório.",
          valid:
            document.querySelector('[name="dt_nascimento"]').value.length > 0,
        },
        uf: {
          message: "O campo uf é obrigatório.",
          valid: document.querySelector('[name="uf"]').value.length > 0,
        },
        instituicao_ensino: {
          message: "O campo instituição de ensino é obrigatório.",
          valid:
            document.querySelector('[name="instituicao_ensino"]').value.length >
            0,
        },
        curso: {
          message: "O campo curso é obrigatório.",
          valid: document.querySelector('[name="curso"]').value.length > 0,
        },
        dt_inicio: {
          message: "O campo data início do curso é obrigatório.",
          valid: document.querySelector('[name="dt_inicio"]').value.length > 0,
        },
        dt_termino: {
          message: "O campo data término do curso é obrigatório.",
          valid: document.querySelector('[name="dt_termino"]').value.length > 0,
        },
      };
      Loading.add();
      let message = {};
      let error = false;
      let apelido = document.querySelector('[name="apelido"]').value + "";
      const url = window.location.href;
    });
  }

  async sendUpdate() {
    let form = document.getElementById("form-meus-dados");
    let salvarDados;
    let obj = {};

    obj["genericCategory"] = "engajamento";
    obj["genericAction"] = "meu_estadao";
    if (form) {
      let bodyFormData = new FormData(form);

      bodyFormData.append("assinatura_selecionada", this.props.assinatura_selecionada.replace(/-1$/, ''))

      Loading.add();
      Form.send("/user", bodyFormData)
        .then((response) => {
          Loading.remove();
          window.scrollTo(0, 0);
          let { data, status } = response;
          if (status === 200) {
            cookie.set("OESP_TALK", "", -1);
            this.props.dispatch(
              alertMessageActions.setAlert("green", "Salvo com sucesso!")
            );
            this.props.dispatch(userActions.getUser(true));
            obj["genericLabel"] = "salvar_dados_pessoais_sucesso";
            salvarDados = new CustomEvent("alertPush", { detail: obj });
            window.dispatchEvent(salvarDados);
          } else if (data.error) {
            let message = data.error.message || "Ops algo deu errado!";
            Form.showErrors(message);
            obj["genericLabel"] = "salvar_dados_pessoais_recusado";
            salvarDados = new CustomEvent("alertPush", { detail: obj });
            window.dispatchEvent(salvarDados);
          }
        })
        .catch((e) => {
          Loading.remove();
        });
    }
  }

  redirectTo(page) {
    this.props.dispatch({ type: "REDIRECT", redirect: "/" + page });
  }

  render() {
    let usuario = this.props.usuario;
    let endereco = this.state;
    let load = !usuario.id;
    usuario.nome_completo = usuario.nome + " " + usuario.sobrenome;
    return (
      <div>
        <div className="title-mobile">Informações pessoais</div>
        <div className="block-single">
          <form
            id="form-meus-dados"
            action=""
            method="POST"
            className="content-form"
          >
            {usuario.telefone && (
              <input name="has_tel" type="hidden" value="1" />
            )}

            {usuario.celular && (
              <input name="has_cel" type="hidden" value="1" />
            )}

            <input type="hidden" name="assinante" value="1" />

            <div className="row">
              <Grid cols="12">
                <Input
                  label="Nome Completo"
                  name="nome_completo"
                  value={usuario.nome_completo}
                  load={load}
                />
              </Grid>
            </div>
            <div className="row">
              <Grid cols="12">
                <Input
                  readonly={true}
                  type="email"
                  label="E-mail"
                  value={usuario.email}
                  load={load}
                />
              </Grid>
            </div>
            <div className="row">
              <Grid cols="12 12 6 6 6">
                <InputPhone
                  name="telefone"
                  label="Telefone"
                  value={usuario.ddd_tel + usuario.telefone}
                  load={load}
                />
              </Grid>
              <Grid cols="12 12 6 6 6">
                <InputPhone
                  name="celular"
                  label="Celular"
                  value={usuario.ddd_cel + usuario.celular}
                  load={load}
                />
              </Grid>
            </div>
            {""}
          </form>
        </div>

        <button
          className="btn blue salvar-dados"
          data-dtkey="salvar_dados"
          onClick={() => {
            this.sendUpdate();
          }}
        >
          <i className="icon-floppy" /> Salvar dados
        </button>

        <button
          className="btn blue redefinir-senha"
          data-dtkey="redefinir_senha"
          onClick={() => {
            this.redirectTo("alterar-senha");
          }}
        >
          <i className="icon-arrows-cw" /> Alterar senha
        </button>

        <button
          className="btn blue redefinir-senha"
          data-dtkey="redefinir_email"
          onClick={() => {
            this.redirectTo("alterar-email");
          }}
        >
          <i className="icon-arrows-cw" /> <spanp>Alterar e-mail</spanp>
        </button>

        {usuario.email_validado !== "S" && (
          <button
            className="btn red salvar-dados valid-email"
            type="button"
            data-dtkey="validar_email"
            onClick={() => {
              this.sendEmailValidateLink();
            }}
          >
            <i className="icon-mail" /> Validar e-mail
          </button>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    usuario: state.user,
    assinatura_selecionada: state.assinaturas.assinatura_selecionada,
    assinatura: state.assinatura.hasOwnProperty(
      state.assinaturas.assinatura_selecionada
    )
      ? state.assinatura[state.assinaturas.assinatura_selecionada]
      : null,
  };
};

export default connect(mapStateToProps)(FormularioAssinante);
