import React from 'react';
import Input from './Input';
import Form from './../Form';
import InputMask from 'react-input-mask';
import axios from "axios";
import Loading from "../Loading";

class InputCEP extends Input {

    constructor(props){
        super(props)

        this.state.search = this.props.search || false;
        this.state.dataTeste = this.props.dataTeste || '';
        this.state.searching = false;
        this.handleChangeCep = this.handleChangeCep.bind(this);
        this.beforeMaskedValueChange = this.beforeMaskedValueChange.bind(this);
    }

    handleChangeCep = e => {
        if(this.state.search){
            let cep = e.target.value.replace(/_/g,"").replace(/-/g,"");
            if(cep.length === 8){
                this._searchAddress(cep);
            }
        }
        this.handleChange(e);
    }

    _searchAddress(cep){
        if(cep && this.state.search && this.state.searching !== cep){
            let callback = this.props.callback || function(){};
            this.setState({
                searching: cep
            });
            Loading.add();
            let error = {};
            error[this.state.name] = "CEP não encontrado!";
            axios.get("https://viacep.com.br/ws/"+cep+"/json")
                .then(response => {
                    let {data} = response;
                    let search = this.state.search;
                    Loading.remove();
                    if(!data.erro){
                        for(let i in search){
                            let element = document.querySelector('[name="'+i+'"]');
                            if(element && data.hasOwnProperty(search[i])){
                                element.setValue(data[search[i]]);
                            }
                        }
                    }else{
                        Form.showErrors(error);
                    }
                    callback(data);
                }).catch(() => {
                    callback({erro: error});
                    Form.showErrors(error);
                    Loading.remove();
                });
        }
    }

    beforeMaskedValueChange = (newState, oldState, userInput) => {
        var { value } = newState;
        var selection = newState.selection;
        var cursorPosition = selection ? selection.start : null;

        // keep minus if entered by user
        if (value.endsWith('-') && userInput !== '-' && !this.state.value.endsWith('-')) {
            if (cursorPosition === value.length) {
                cursorPosition--;
                selection = { start: cursorPosition, end: cursorPosition };
            }
            value = value.slice(0, -1);
        }

        return {
            value,
            selection
        };
    }

    render(){
        return (
            <div ref={el => this.input_cep = el || this.input_cep} className={this.state.className.join(" ")+(this.state.isset ? " isset" : "")}>
                {!this.state.load ? (
                    <div>
                        <InputMask readOnly={this.state.readonly} ref={el => this.input = el || this.input} mask="99999-999" data-teste={this.state.dataTeste} maskChar={null} beforeMaskedValueChange={this.beforeMaskedValueChange}  type={this.state.type} id={this.state.id} name={this.state.name} className={(!this.state.label ? "no-label" : "")} onChange={(e) => this.handleChangeCep(e)} defaultValue={this.state.value}/>
                        {this.state.label ? (
                            <label htmlFor={this.state.id}>{this.state.label}</label>
                        ) : ""}
                    </div>
                ) : this.loader()}
            </div>
        )
    }
}

export default InputCEP;
