import React, {Component} from 'react'
import {connect} from "react-redux";
import Grid from '../../modules/Grid'

class Apple extends Component {
    render() {
        return(
            <div className='google-container apple_container'>
                <div className='row align-content-center justify-content-center'>
                    <Grid cols='8 8 3 3'>
                        <div className='apple_box'>
                            <img src="/static/img/logo-apple.png" alt="" />
                        </div>
                    </Grid>
                    <Grid cols='12 12 9 9'>
                        <p className='apple-descr apple_text'>
                            <b>Sua assinatura do Estadão está vinculada à sua conta Apple.</b>
                        </p>
                        <p className='apple-descr'>
                            Para desvincular, <a href="https://support.apple.com/pt-br/102571" target="_blank" rel="noopener noreferrer">clique aqui</a> e saiba mais.
                        </p>
                        <p className='apple-descr'>
                            Após esta ação, basta retornar à home e entrar com seu login e senha.<br/>Assim você terá acesso às suas informações originais e poderá editá-las no ‘Meu Estadão'.
                        </p>
                    </Grid>

                </div>
            </div>
        )
    }
}


const mapStateToProps = state => {
    return {
        ...state,
        assinatura_selecionada: state.assinaturas.assinatura_selecionada,
        assinatura: state.assinatura.hasOwnProperty(state.assinaturas.assinatura_selecionada) ? state.assinatura[state.assinaturas.assinatura_selecionada] : null
    }
};

export default connect(mapStateToProps)(Apple)